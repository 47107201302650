@tailwind base;
@tailwind components;
@tailwind utilities;

/* For WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar handle color */
  border-radius: 10px; /* Rounded edges for the handle */
  border: 3px solid transparent; /* Padding around the handle */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Handle color on hover */
}

.p {
  margin-bottom: 30px;
}
